<template>
  <PageSection
    class="BrandsSettings"
    :page-title="$t('pages.settings.personal.brands_settings.headline')"
  >
    <div class="m-b-m">
      {{ $t('pages.settings.personal.brands_settings.sort_description') }}
    </div>

    <div v-loading="isLoading">
      <draggable v-model="computedBrandsOrder" class="SortableBrandsList">
        <div
          v-for="brand in computedBrandsOrder"
          :key="brand.id"
          class="m-b-m p-a-s has-background-grey-lighter has-text-black has-text-weight-semibold is-flex is-aligned-middle SortableBrandsList__item"
          data-testid="SortableBrandsListItem"
        >
          <RoundAvatar
            :src="brand.logo.cropped"
            :acronym-fallback="brand.name"
            size="small"
            class="m-r-m"
          />

          <div class="m-r-a">
            {{ brand.name }}
          </div>

          <VButton
            :title="$t('pages.settings.personal.brands_settings.button_tooltip')"
            :loading="isRemoving === brand.id"
            class="is-danger is-narrow is-plain m-r-s"
            @click="removeUserFromBrand(brand)"
          >
            <div class="icon">
              <VIcon type="times-thin" />
            </div>
          </VButton>

          <v-icon
            class="m-r-m"
            size="lg"
            type="drag-handle"
          />
        </div>
      </draggable>
    </div>
  </PageSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'

import RoundAvatar from '@hypefactors/shared/js/components/core/RoundAvatar.vue'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import VIcon from '@hypefactors/shared/js/components/core/VIcon.vue'
import { UserApiService } from '@hypefactors/shared/js/services/api/UserApiService.js'

import PageSection from '@/components/core/PageSection.vue'

export default {
  components: {
    RoundAvatar,
    VButton,
    VIcon,
    PageSection,
    draggable
  },

  data () {
    return {
      brandsCached: null,
      isLoading: false,
      isRemoving: null
    }
  },

  computed: {
    ...mapGetters(['brands']),

    computedBrandsOrder: {
      get () {
        return this.brandsCached || this.brands
      },
      set (value) {
        this.handleBrandOrderUpdate(value)
      }
    }
  },

  methods: {
    ...mapActions(['updateBrandsOrder', 'fetchUserBrandsRequest']),

    handleBrandOrderUpdate (brands) {
      this.brandsCached = brands

      this.isLoading = true

      this.updateBrandsOrder(brands)
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isLoading = false

          this.brandsCached = null
        })
    },

    async removeUserFromBrand (brand) {
      try {
        await this.$confirm(this.$t('pages.settings.personal.brands_settings.confirm_remove_brand'))

        if (this.brands.length === 1) {
          await this.$confirm(this.$t('pages.settings.personal.brands_settings.confirm_last_brand_removal'))
        }
        this.isRemoving = brand.id

        await UserApiService.removeSelfFormBrand(brand.id)

        await this.fetchUserBrandsRequest()
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isRemoving = null
      }
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/vueStyleUtils.scss';

.SortableBrandsList {
  .SortableBrandsList__item {
    cursor: grab;
  }

  .sortable-chosen {
    background-color: $grey-light !important;
    cursor: grabbing !important;
  }
}
</style>
